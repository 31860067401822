.user {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin: 0px 10px;
	gap: 15px;

	.arrow_down-icon {
		margin-left: 10px;
	}

	.link {
		border: 2px solid green;
		text-decoration: none;
	}
}
