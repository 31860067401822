.pricing_page {
	padding: 20px;
	min-height: calc(100vh - 60px);

	.heading {
		margin-top: 50px;
		text-align: center;
	}

	.subheading {
		text-align: center;
		margin-top: 10px;
	}

	.prices {
		display: flex;
		gap: 20px;
		justify-content: center;
	}
}
