.hero {
	background-image: url("../../assets/hero.jpeg");
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: calc(100vh - 60px);

	.hero_content {
		display: flex;
		flex-direction: column;
		gap: 50px;
		width: 450px;
		margin-left: 100px;
	}
}
