.footer {
	position: relative;
	min-height: 200px;
	background-color: #1a1a1d;
	padding: 20px;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-items: center;

	.logo_wrapper {
		// position: absolute;
		// top: 50%;
		// transform: translateY(-50%);
		background-color: #fff;
		width: 210px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
	}

	.logo {
		width: 200px;
	}

	p {
		color: #fff;
	}

	.copyright-text {
		// position: absolute;
		// bottom: 10px;
		// left: 50%;
		// transform: translateX(-50%);
		// text-align: center;
	}
}
