.dashboard_header {
	padding: 0px 20px;
	position: relative;
	// background-color: #002b5b;
	// background-color: #001529;
	// background-color: $theme-primary-bg-color;
	background-color: $purple-theme;
	color: #fff;
	display: flex;
	// flex-direction: column;
	// justify-content: space-evenly;

	.left_side-container {
		display: flex;
		flex: 1;
		justify-content: space-evenly;
	}

	.logo_container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo {
		width: 180px;
	}

	.navigation {
		height: 100%;
		display: flex;
		gap: 35px;
		position: relative;
		left: 50px;
	}

	.navigation_item {
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	.navigation_item > a {
		flex-direction: column;
		color: #fff;
		text-decoration: none;
		display: flex;
		justify-content: center;
		align-items: center;
		// gap: 10px;
	}

	.navigation_item a.active {
		// color: rgb(92, 92, 247);
		color: #000;
	}

	.active_page {
		border-bottom: 2px solid #1677ff;
	}

	.right_center {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1;
	}

	.navigation_search {
		border: none;
		padding: 6px;
		border-radius: 4px;
		width: 250px;
		align-self: center;
	}

	.settings_notification-buttons {
		border-right: 1px solid lightgray;
		padding: 10px;
		display: flex;
		gap: 10px;
	}

	.icon_wrapper {
		cursor: pointer;
		margin-right: 10px;
	}

	.user_dropdown {
		position: relative;
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 15px;
		width: 180px;
		justify-content: center;
	}

	.dropdown_content {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.user_name {
		font-size: 17px;
	}

	.user_title {
		font-size: 14px;
	}

	.dropdown_content span {
		line-height: 1.2em;
	}

	.user_dd-icon {
		position: relative;
		top: 5px;
	}

	.searchbar {
		background-color: #fff;
		display: flex;
		align-items: center;
	}

	.search_input {
		border: none;
	}

	.dropdown {
		z-index: 10;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
			rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
			rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
		position: absolute;
		background-color: #fff;
		top: 100%;
		color: #000;
		min-height: 100px;
		border-radius: 0px 0px 4px 4px;
		width: 140px;
	}

	.dropdown ul {
		margin: 10px;
	}

	.dropdown li {
		padding: 10px;
		border-bottom: 1px solid lightgray;
		cursor: pointer;
	}
}
