.customers {
	padding: 25px;
	display: flex;
	flex-direction: column;
	gap: 40px;

	.customers_heading {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
