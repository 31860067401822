.profile {
	padding: 25px;
	background-color: $bg;
	min-height: calc(100vh - 50px);
	width: 100%;

	.card {
		padding: 20px 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.settings_form {
		display: flex;
		flex-direction: column;
		width: 40%;
		align-items: center;
		gap: 25px;
	}
}
