.login {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.logo_container {
		flex: 1;
		height: 100%;
		display: flex;
		background-color: $theme-primary-bg-color;
		align-items: center;
		justify-content: center;
	}

	.login_logo {
		width: 350px;
		position: relative;
		bottom: 20px;
	}

	.form_container {
		flex: 1.5;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.login_form {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 380px;
		height: 300px;
		align-items: center;
	}

	.input_wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.input_label {
		padding-left: 10px;
	}

	.button_group {
		text-align: center;
		width: 100%;
	}

	.forgot_password-text a {
		text-decoration: none;
		color: #000;
	}

	.error_message {
		text-align: center;
		color: #ed6d53;
	}

	.MuiInputBase-root {
		border: none;
		background-color: #fff;
	}
}
