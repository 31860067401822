.error {
	width: 100%;
	height: 40px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	color: rgb(95, 33, 32);
	background-color: rgb(253, 237, 237);
}

.register_page-error {
	@include error_box;
	// background-color: rgb(253, 237, 237);
	// width: 100%;
	// text-align: center;
	// padding: 5px;
	// border-radius: 5px;
	// color: rgb(95, 33, 32);
}
