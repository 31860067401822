.forgot_password-page {
	height: 100vh;

	.forgot_password-logo {
		margin-top: 50px;
		max-width: 340px;
	}

	.back_btn-container {
		text-align: center;
	}
}
