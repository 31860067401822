.header {
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 20px;
	margin: 0px 20px;

	.logo {
		width: 200px;
	}

	.navigation_list {
		display: flex;
		gap: 70px;
	}

	.nav_item {
		font-size: 18px;
		font-weight: 500;
	}

	.nav_item a {
		color: #000;
		text-decoration: none;
	}

	.nav_item a.active {
		color: #1976d2;
	}

	.action_buttons-container {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.action_buttons-container a {
		color: #000;
	}
}
