// @import "~rsuite/dist/rsuite.min.css";

@import "./abstract/variables";
@import "./abstract/mixins";
// @import "./base/reset.scss";

@import "./components/modal";
@import "./components/pageLoading";
@import "./components/hero";
@import "./components/priceCard";
@import "./components/inputGroup";
@import "./components/dropdown";
@import "./components/user";
@import "./components/authError";

// Import layouts
@import "./layouts/publicLayout";
@import "./layouts/dashboardHeader";
@import "./layouts/siteHeader";
@import "./layouts/footer";

// Import pages
@import "./pages/portal/profile.scss";
@import "./pages/portal/employees.scss";
@import "./pages/portal/customers.scss";
@import "./pages/home";
@import "./pages/landing";
@import "./pages/login";
@import "./pages/forgotPassword";
@import "./pages/register";
@import "./pages/pricing";
@import "./pages/contact";
@import "./pages/dashboard";
@import "./pages/calendar";
@import "./pages/settings/settings";

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	list-style: none;
}

/* Set core body defaults */
html,
body {
	min-height: 100%;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	max-width: 100vw;
	overflow: auto;
}

.portal {
	min-height: 100vh;
	background-color: $bg;
}
