.dashboard {
	position: relative;
	padding: 20px;
	// min-height: calc(100% - 60px);

	.dashboard_heading-container {
		display: flex;
		justify-content: space-between;
	}

	.dashboard_content {
		border: 1px solid red;
	}
}
