.modal {
	height: 100%;
	width: 100%;
}

.new_appointment-modal {
	background-color: #fff;
	width: 400px;
	color: #000;

	.name_wrapper,
	.contact_wrapper {
		display: flex;
	}

	.input_wrapper {
		display: flex;
		flex-direction: column;
		border: 1px solid green;
		flex: 1;
	}

	.radio_buttons-container {
		display: flex;
		flex-direction: column;
	}

	.radio_buttons-container label {
		display: flex;
		gap: 5px;
	}
}

.modal_overlay {
	.container {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		max-width: 450px;
	}

	.service_selection {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		gap: 10px;
	}

	.button_group {
		margin-top: 40px;
		display: flex;
		justify-content: flex-end;
		gap: 15px;
	}
}
