.register {
	height: 100vh;
	// background-color: $login-bg-color;
	display: flex;
	align-items: center;
	justify-content: center;

	.logo_container {
		flex: 1;
		height: 100%;
		display: flex;
		background-color: $theme-primary-bg-color;
		align-items: center;
		justify-content: center;
	}

	.form_container {
		flex: 1.5;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.register_logo {
		width: 350px;
		position: relative;
		bottom: 20px;
	}

	.register_form {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		gap: 20px;
	}

	.input_wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.group {
		display: flex;
		gap: 15px;
	}

	.password_group {
		width: 100%;
		border: 1px solid red;
	}

	.group_input {
		width: 49%;
		padding: 16px 12px;
		border: none;
		border-radius: 4px;
		font-size: 16px;
	}

	.first_name-input,
	.last_name-input {
		width: 48%;
	}

	.first_name-input,
	.last_name-input,
	.auth_input {
		padding: 16px 12px;
		border: none;
		border-radius: 4px;
		font-size: 16px;
	}

	.auth_input::placeholder {
		font-size: 15px;
	}

	.button_container {
		text-align: center;
	}

	.forgot_password-text {
		position: relative;
		top: 20px;
	}

	.forgot_password-text a {
		text-decoration: none;
		color: #000;
	}
}
