.contact {
	min-height: calc(100vh - 190px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 20px 40px 20px;

	.form {
		width: 420px;
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-top: 40px;
	}

	.name_inputs-container {
		display: flex;
		justify-content: space-between;
	}

	.contact_form-success {
		color: rgb(30, 70, 32);
		background-color: rgb(237, 247, 237);
		width: 100%;
		height: 40px;
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contact_form-error {
		background-color: rgb(253, 237, 237);
		border-radius: 5px;
		color: rgb(95, 33, 32);
		width: 100%;
		height: 40px;
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
