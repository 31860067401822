.landing {
	.solutions {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 350px;
		padding: 20px;
		// border: 1px solid red;
	}

	.solutions_title {
		text-align: center;
	}

	.solutions_content {
		display: flex;
		margin-top: 40px;
		justify-content: space-evenly;

		gap: 20px;
	}

	.solutions_card {
		max-width: 380px;
	}
}

.service_icons-section {
	padding: 20px;

	.service_list {
		display: flex;
		gap: 40px;
		justify-content: space-evenly;
	}

	.service_item {
		display: flex;
		gap: 15px;
		align-items: center;
		max-width: 15%;
	}
}

.contact_section {
	// background-color: #fcedfa;
	background-color: #fbfbfb;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 50px;
	min-height: 250px;
	padding: 20px;

	.contact_section-title {
		text-align: center;
	}
}
